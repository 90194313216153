<template>
  <div>
    <ContentView>
      <Subheader title="Contratistas" />
      <b-row align-h="end">
        <b-form-select v-model="category" class="mb-3" style="width: 160px">
          <option value="Activos">Activos</option>
          <option value="Bloqueados">Bloqueados</option>
        </b-form-select>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-table
          outlined
          hover
          responsive
          thead-class="light-yellow"
          sort-by="last_name"
          :tbody-tr-class="rowStyling"
          :items="categorizedContractors"
          :fields="fields"
          :filter="filter"
          class="fs-13"
          @row-clicked="onRowClick"
        >
          <!-- Loading spinner -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando...</span>
            </div>
          </template>

          <!-- Table Data -->
          <template v-slot:cell(first_name)="data">
            <span>{{ data.item.first_name }}</span>
            <span v-if="data.item.second_name">{{ data.item.second_name }}</span>
          </template>
          <template v-slot:cell(last_name)="data">
            <span>{{ data.item.last_name }}</span>
            <span v-if="data.item.last_name2">{{ data.item.last_name2 }}</span>
          </template>
          <template v-slot:cell(role)="data">
            <span>{{ data.value | titleize }}</span>
          </template>
          <template v-slot:cell(confirmed)="data">
            <div class="text-center">
              <div v-if="data.item.status == 'activo'">
                <div v-if="!data.item.confirmed">
                  <b-link class="text-success" @click="openConfirmModal(data.item.id)">
                    Confirmar
                  </b-link>
                  <b-link class="text-danger ml-3" @click="denyConfirmation(data.item.id)">
                    Rechazar
                  </b-link>
                </div>
              </div>
              <div v-else>
                <b-link @click="reactivate(data.item.id)">
                  Desbloquear
                </b-link>
              </div>
            </div>
          </template>
        </b-table>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modal -->
    <b-modal centered v-model="confirmModalShow" title="Confirmar contratista" hide-footer>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 250px">
          <ValidationProvider rules="required" name="Autorización" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Autorización</span><span class="asterisk">*</span>
              </template>
              <b-select
                v-model="confirmForm.role"
                size="sm"
                :options="roleOptions"
                :state="errors[0] ? false : (valid ? true : null)"
              ></b-select>
            </b-form-group>
          </ValidationProvider>
          <br>
          <ValidationProvider rules="required" name="Puesto" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Puesto</span><span class="asterisk">*</span>
              </template>
              <b-input
                v-model="confirmForm.job_title"
                type="text"
                size="sm"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>
          <div class="text-center mt-5 mb-2">
            <Button variant="green" @click="confirmContractor" :disabled="invalid">Confirmar</Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';
import ToastAlert from '../shared/ToastAlert';

export default {
  name: 'ContractorIndex',
  components: { ValidationObserver, ValidationProvider, Button, ContentView, Subheader, ToastAlert },
  data() {
    return {
      contractors: [],
      confirmModalShow: false,
      confirmForm: {
        confirmed: true,
        job_title: null,
        role: null
      },
      category: 'Activos',
      fields: [
        { key: 'first_name', label: 'Nombre', sortable: true, tdClass: 'clickable' },
        { key: 'last_name', label: 'Apellidos', sortable: true, tdClass: 'clickable' },
        { key: 'email', label: 'Correo', tdClass: 'clickable' },
        { key: 'role', label: 'Autorización', tdClass: 'clickable' },
        { key: 'job_title', label: 'Puesto', tdClass: 'clickable' },
        { key: 'confirmed', label: '', tdClass: 'last-column clickable' }
      ],
      roleOptions: [
        { value: null, text: '- Eligir uno -'},
        { value: 'vendedor', text: 'Vendedor' },
        { value: 'técnico', text: 'Técnico' },
        { value: 'distribuidor', text: 'Distribuidor' },
        { value: 'instalador', text: 'Instalador' },
        { value: 'non_prod', text: 'No es de producción' }
      ],
      filter: null,
      loading: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      unconfirmedUserId: null
    }
  },
  computed: {
    categorizedContractors() {
      if (this.category == 'Activos') {
        return this.contractors.filter(c => c.status == 'activo');
      } else {
        return this.contractors.filter(c => c.status == 'inactivo');
      }
    },
    rowStyling() {
      if (this.category == 'Bloqueados') { return 'text-danger' }
    }
  },
  mounted() {
    this.fetchContractors();
  },
  methods: {
    fetchContractors() {
      this.loading = true;
      this.$http.get('/api/contractors/users')
        .then(response => {
          console.log(response)
          this.contractors = response.data;
        })
        .catch(error => {
          // TODO
          console.log(error)
        })
        .finally(() => this.loading = false);
    },
    openConfirmModal(userId) {
      this.confirmModalShow = true;
      this.unconfirmedUserId = userId;
    },
    confirmContractor() {
      this.confirmModalShow = false;
      this.$http.put(`/api/contractors/users/${this.unconfirmedUserId}/confirm`, {
          user: this.confirmForm
        })
        .then(response => {
          console.log(response)
          this.fetchContractors();
          this.alertVariant = 'success';
          this.alertMessage = 'La cuenta del contratista fue confirmada';
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          // TODO show validation errors if any
          this.alertMessage = 'No funcionó';
        })
        .finally(() => this.alertShow = true)
    },
    reactivate(userId) {
      if (this.processing) { return }
      this.processing = true;
      this.$http.put(`/api/contractors/users/${userId}/reactivate`)
        .then(response => {
          console.log(response)
          this.alertVariant = 'success'
          this.alertMessage = 'Contratista se restauró con éxito';
          this.fetchContractors();
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
        })
        .finally(() =>  {
          this.processing = false;
          this.alertShow = true;
        });
    },
    onRowClick(item) {
      if (item.confirmed) { this.$router.push(`/app/contratistas/${item.id}`) }
    },
    denyConfirmation(userId) {
      if (this.processing) { return }
      this.processing = true;
      this.$http.delete(`/api/contractors/users/${userId}`)
        .then(response => {
          console.log(response)
          this.contractors = this.contractors.filter(c => c.id !== response.data.id);
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    }
  }
}
</script>
