<template>
  <b-card
    bg-variant="light"
    class="quote-card"
    @click="$router.push(routerUrl)"
  >
    <b-row>
      <b-col>
        <span>{{ quote.quote_id }}</span>
      </b-col>
      <b-col class="text-right">
        <span>{{ quote.total | dollarize }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <span v-if="quote.title">{{ quote.title }}</span>
        <span v-else>( Sin titulo )</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'QuoteCard',
  props: {
    quote: {
      type: Object,
      required: true
    },
    distributor: {
      type: Boolean
    }
  },
  computed: {
    routerUrl() {
      return this.distributor ? `/app/dist/cotizador/${this.quote.id}` : `/app/prod/cotizador/${this.quote.id}`;
    }
  }
}
</script>

<style scoped>
.quote-card {
  width: 300px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.quote-card:hover {
  transform: scale(1.05);
}
.card-body {
  padding: 5px 10px;
}
</style>
